<template>
    <div>
      <PageLoader :storage="appLoading" />
      <ServerError v-if="ServerError" />
      <v-snackbar v-model="showSnackBar" color="#FF6907" right :timeout="timeout">
        <v-layout wrap justify-center>
          <v-flex text-left class="align-self-center">
            <span style="color: #fff">{{ msg }}</span>
          </v-flex>
          <v-flex text-right>
            <v-btn small :ripple="false" text @click="showSnackBar = false">
              <v-icon style="color: #fff">mdi-close</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-snackbar>
      <v-layout wrap justify-center>
        <v-flex xs12>
          <v-layout wrap justify-start pa-5>
            <v-layout row justify-space-between align-center px-2>
              <v-flex xs6>
                <span class="heading">District Emergency Offficer List</span>
              </v-flex>
              <!-- <v-layout wrap justify-end pt-2>
                <v-flex xs12 sm8 md8 lg6>
                  <v-text-field
                        :hide-details="true"
                         v-model="keyword"
                          append-icon="mdi-magnify"
                          label="Search"
                          solo  clearable
                          dense flat outlined
                         style="font-family:sofiaProRegular;font-size:13px;"
                        ></v-text-field>
                </v-flex>
                </v-layout> -->
            </v-layout>
  
            <!-- <v-flex xs12>
              <v-layout wrap justify-start pt-2>
                <v-flex xs12 v-if="userlist && userlist.length > 0">
                  <v-layout wrap justify-start>
                    <v-flex xs12 pt-4>
                      <v-layout wrap>
                        <v-flex
                          xs12
                          lg4
                          sm6
                          md6
                          v-for="(item, index) in userlist"
                          :key="index"
                          pa-2
                        >
                          <v-card class="mb-4">
                            <v-card-title>
                              <span class="heading">{{ item.name }}</span>
                            </v-card-title>
                            <v-card-subtitle>
                              <span class="subheading">
                                <span v-if="item.is_rescuer">Rescuer</span>
                                <span v-else>User</span> </span
                              ><br />
                              <span class="subheading">{{ item.email }}</span
                              ><br />
                              <span class="subheading">{{ item.phone }}</span>
                            </v-card-subtitle>
                            <v-card-actions>
                              <v-btn
                                color="green"
                                style="font-family: poppinsmedium"
                                small
                                plain
                                @click="openEditDialog(item)"
                              >
                                <v-icon small>mdi-pencil</v-icon><span>Edit</span>
                              </v-btn>
                              <v-btn
                                color="error"
                                style="font-family: poppinsmedium"
                                small
                                plain
                                @click="openDeleteDialog(item)"
                              >
                                <v-icon small>mdi-delete</v-icon
                                ><span>Delete</span>
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex> -->
            <v-flex xs12 v-if="userlist && userlist.length > 0" pt-2>
             
              <v-layout wrap justify-start pt-2>
                <v-flex xs12>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left tablehead">Name</th>
                           <th class="text-left tablehead">District</th>
                          <th class="text-left tablehead">Phone</th>
                          
  
                          <!-- <th class="text-left tablehead">Edit</th>
  
                          <th class="text-left tablehead">Delete</th> -->
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="cursor: pointer"  v-for="(item, index) in userlist" :key="index" @click="openDetailsDialog(item)">
                          <td class="tableitems">{{ item.name }}</td>
                          <td class="tableitems">{{ item.district }}</td>
                          <td class="tableitems">{{ item.phone }}</td>
                         
                         
  
  
                          <!-- <td class=" action-column">
                            <v-btn color="success" depressed small @click.stop="openEditDialog(item)"
                            ><span>Edit</span></v-btn>
                          </td>
  
                          <td>
                            <v-btn color="error" depressed small
                            @click.stop="openDeleteDialog(item)"
                            ><span>Delete</span></v-btn>
                          </td> -->
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 v-else>
              <v-layout wrap justify-center align-center>
                <v-flex xs12 class="text-center">
                  <span class="nodata">No Data Found !</span>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex xs12 pb-4 v-if="userlist && userlist.length > 0">
          <v-layout wrap justify-center>
            <v-flex xs10>
              <v-pagination
                v-model="currentPage"
                :length="Pagelength"
                circle
                color="#FF6907"
                small
              ></v-pagination>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
      <v-dialog v-model="confirmDeleteDialog" max-width="500px">
        <v-card>
          <v-card-title class="itemHeading">Confirm Delete</v-card-title>
          <v-card-text
            >Are you sure you want to delete this user?</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="confirmDeleteDialog = false"
              class="itemValue"
            >
              Cancel
            </v-btn>
            <v-btn
              tile
              :color="appColor"
              light
              :ripple="false"
              depressed
              @click="deleteUser"
              class="itemValue"
            >
              <span style="color: #fff">Delete</span>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </template>
    <script>
  import axios from "axios";
  export default {
    data() {
      return {
        appLoading: false,
        ServerError: false,
        showSnackBar: false,
        timeout: 5000,
        msg: null,
        keyword: "",
        page: 1,
        currentPage: 1,
        pages: 0,
        count:20,
        userlist: [],
        confirmDeleteDialog: false,
        
      };
    },
    beforeMount() {
      this.getData();
    },
    watch: {
      currentPage() {
        this.getData();
      },
      count() {
        this.getData();
      },
      keyword() {
        this.getData();
      },
    },
    methods: {
      openDeleteDialog(item) {
        this.deleteUserItem = item;
        this.confirmDeleteDialog = true;
      },
      deleteUser() {
        this.appLoading = true;
        axios({
          method: "DELETE",
          url: "/user/delete",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            id: this.deleteUserItem._id,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              this.msg = "User deleted successfully.";
              this.showSnackBar = true;
              this.appLoading = false;
              this.confirmDeleteDialog = false;
              this.getData();
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      openEditDialog(item) {
        this.$router.push({
          path: "/edituser",
          query: {
            id: item._id,
          },
        });
      },
      getData() {
        this.appLoading = true;
        axios({
          method: "GET",
          url: "/district/emergencyofficer/getlist",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          params: {
            count: this.count,
            page: this.currentPage,
            keyword: this.keyword,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.userlist = response.data.data;
                this.Pagelength = response.data.pages;
                this.msg = response.data.msg;
                this.showSnackBar = false;
                this.appLoading = false;
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
      handlesubmit() {
        this.appLoading = true;
        axios({
          method: "POST",
          url: "/snake/",
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
          data: {
            name: this.commonname,
            scientificName: this.scientificname,
            states: this.state,
            rname: this.reginonalname,
            distribution: this.distribution,
            characteristics: this.scalation,
            description: this.description,
            venomType: this.venomtype,
            family: this.family,
            occurence: this.occurence,
            wlpa: this.wlpa,
            iucn: this.iucn,
            shortdes: this.shortdescription,
            othernames: this.othername,
            genus: this.genus,
            experts: this.experts,
          },
        })
          .then((response) => {
            if (response.data.status == true) {
              {
                this.msg = response.data.msg;
                this.showSnackBar = true;
                this.appLoading = false;
                this.commonname = "";
                this.scientificname = "";
                this.state = "";
                this.reginonalname = "";
                this.distribution = "";
                this.scalation = "";
                this.description = "";
                this.venomtype = "";
                this.family = "";
                this.occurence = "";
                this.wlpa = "";
                this.iucn = "";
                this.shortdescription = "";
                this.othername = "";
                this.genus = "";
                this.experts = "";
              }
            } else {
              this.msg = response.data.msg;
              this.showSnackBar = true;
              this.appLoading = false;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            (this.ServerError = true), console.log(err);
          });
      },
    },
  };
  </script>
    <style>
  .heading {
    font-family: poppinsregular;
    font-size: 20px;
    font-weight: 900;
  }
  .subheading {
    font-family: poppinsregular;
    font-size: 15px;
    font-weight: 500;
  }
  </style>